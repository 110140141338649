import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-banner',
  templateUrl: './big-banner.component.html',
  styleUrls: ['./big-banner.component.scss']
})
export class BigBannerComponent implements OnInit {
  image1 = ("./assets/images/banner/001.jpg")
  image2 = ("./assets/images/banner/002.jpg")
  image3 = ("./assets/images/banner/003.jpg")
  constructor() { }

  ngOnInit(): void {
  }

}
