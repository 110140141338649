import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnInit {
  imgSlider = [
    {
      id: 1,
      imgUrl: './assets/images/collection/img1.webp',
      title:'Necklaces',
      more:'We have a tremendous variety in comparison to all of our competitors.'
    },
    {
      id: 2,
      imgUrl: './assets/images/collection/img2.webp',
      title:'Jewelry Sets',
      more:'There are two types of jewelry – stones and metals. Obviously both of them are unique because of their appearance.'
    },
    {
      id: 3,
      imgUrl: './assets/images/collection/img3.webp',
      title:'Rings',
      more:'Precious metals have always been a detector of prosperity and high social rank.'
    },
    {
      id: 4,
      imgUrl: './assets/images/collection/img4.webp',
      title:'Bracelets',
      more:'Obviously both of them are unique because of their appearance.'
    },
    {
      id: 5,
      imgUrl: './assets/images/collection/img1.webp',
      title:'Earrings',
      more:'We are offering you the unique goods because our product is the real treasure.'
    }
  ];
  constructor() {}

  ngOnInit(): void {}
}
