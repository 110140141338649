import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
  image1 = ("./assets/images/offers/cms-banner-1.jpg");
  image2 = ("./assets/images/offers/cms-banner-2.jpg");

  offers:any = {
    src : ("./assets/images/offers/cms-banner-1.jpg")
  }
  constructor() { }

  ngOnInit(): void {
  }

}
