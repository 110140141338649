
<div class="wrapper">
    <div class="outer">
        <div class="inner">
            <img class="logo" [src]="LOGO" alt="logo">
        </div>
    </div>
    <!-- <nav>
        <div class="wrapper">
            <div class="logo"><a href="#">Vatturkar</a></div>
            <input type="radio" name="slider" id="menu-btn">
            <input type="radio" name="slider" id="close-btn">
            <ul class="nav-links">
                <label for="close-btn" class="btn close-btn"><i class="fas fa-times"></i></label>
                <li><a href="#">Home</a></li>
                <li>
                    <a href="#" class="desktop-item">Gold</a>
                    <input type="checkbox" id="showMega">
                    <label for="showMega" class="mobile-item">Mega Menu</label>
                    <div class="mega-box">
                        <div class="content">
                            <div class="row">
                                <img [src]="THUM1" alt="">
                            </div>
                            <div class="row">
                                <header>TYPE</header>
                                <ul class="mega-links">
                                    <li><a href="#"> Bangles</a> </li>
                                    <li><a href="#"> Bracelets</a> </li>
                                    <li><a href="#"> Bracelets</a> </li>
                                    <li><a href="#"> Earrings</a> </li>
                                    <li><a href="#"> Gold Chains</a> </li>

                                </ul>
                            </div>
                            <div class="row">
                                <header></header>
                                <ul class="mega-links">
                                    <li><a href="#"> Pendants</a> </li>
                                    <li><a href="#"> Rings</a> </li>
                                    <li><a href="#"> Necklaces</a> </li>
                                    <li><a href="#"> Nose Pins</a> </li>
                                    <li><a href="#"> Kadas</a> </li>
                                    <li><a href="#"> Mangalsutras</a> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="#">Silver</a></li>
                <li><a href="#">About</a></li>
                <li>
                    <a href="#" class="desktop-item">Mega Menu</a>
                    <input type="checkbox" id="showMega">
                    <label for="showMega" class="mobile-item">Mega Menu</label>
                    <div class="mega-box">
                        <div class="content">
                            <div class="row">
                                <img src="img.jpg" alt="">
                            </div>
                            <div class="row">
                                <header>Design Services</header>
                                <ul class="mega-links">
                                    <li><a href="#">Graphics</a></li>
                                    <li><a href="#">Vectors</a></li>
                                    <li><a href="#">Business cards</a></li>
                                    <li><a href="#">Custom logo</a></li>
                                </ul>
                            </div>
                            <div class="row">
                                <header>Email Services</header>
                                <ul class="mega-links">
                                    <li><a href="#">Personal Email</a></li>
                                    <li><a href="#">Business Email</a></li>
                                    <li><a href="#">Mobile Email</a></li>
                                    <li><a href="#">Web Marketing</a></li>
                                </ul>
                            </div>
                            <div class="row">
                                <header>Security services</header>
                                <ul class="mega-links">
                                    <li><a href="#">Site Seal</a></li>
                                    <li><a href="#">VPS Hosting</a></li>
                                    <li><a href="#">Privacy Seal</a></li>
                                    <li><a href="#">Website design</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="#">Feedback</a></li>
            </ul>
            <label for="menu-btn" class="btn menu-btn"><i class="bi bi-list"></i></label>
        </div>
    </nav> -->
</div>