<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h4>MORE INFO</h4>
        <ul class="nav-bottom">
          <li><a href="#">How to order</a></li>
          <li><a href="#">FAQ</a></li>
          <li><a href="contact.html">Location</a></li>
          <li><a href="#">Shipping</a></li>
          <li><a href="#">Membership</a></li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="row">
          <h4>CONTACT US</h4>
          <div class="col-md-6">
            <p>SADASHIV PETH</p>
            <p>589 Sadashiv Peth,Arihant Market,</p>
            <p>Laxmi Road,Pune 411030</p>
            <p>office: 020 24451771 </p>
            <p>Mob: +91 58560600</p>
            <p>Email:vatturkarsaraf589@gmail.com</p>
            <ul class="social">
              <li><a target="_blank" href="https://www.facebook.com/Vatturkar/?fref=ts"><i> </i></a></li>
              <li><a href="#"><i class="twitter"> </i></a></li>
              <li><a href="#"><i class="rss"> </i></a></li>
              <li><a href="#"><i class="gmail"> </i></a></li>
            </ul>
          </div>
          <div class="col-md-6">
            <p>KOTHRUD</p>
            <p>Kothrud,Pune 411030</p>
            <p>office: 020 24451771 </p>
            <p>Mob: +91 9158560600</p>
            <p>Email:vatturkarsaraf589@gmail.com</p>
          </div>
        </div>
      </div>
      <div class="col footer-class">
        <p>© 2015 Vatturekar Saraf All Rights Reserved</p>
      </div>
    </div>
  </div>
</footer>

<!-- 

<div class="footer">
  <div class="container">
    <div class="row footer-top-at">
      <div class="col-md-6 amet-sed">
        <h4>MORE INFO</h4>
        <ul class="nav-bottom">
          <li><a href="#">How to order</a></li>
          <li><a href="#">FAQ</a></li>
          <li><a href="contact.html">Location</a></li>
          <li><a href="#">Shipping</a></li>
          <li><a href="#">Membership</a></li>
        </ul>
      </div>
      <div class="col-md-6 amet-sed ">
        <div class="row">
          <h4>CONTACT US</h4>
          <div class="col-md-6">
            <p>SADASHIV PETH</p>
            <p>589 Sadashiv Peth,Arihant Market,</p>
            <p>Laxmi Road,Pune 411030</p>
            <p>office: 020 24451771 </p>
            <p>Mob: +91 58560600</p>
            <p>Email:vatturkarsaraf589@gmail.com</p>
            <ul class="social">
              <li><a target="_blank" href="https://www.facebook.com/Vatturkar/?fref=ts"><i> </i></a></li>
              <li><a href="#"><i class="twitter"> </i></a></li>
              <li><a href="#"><i class="rss"> </i></a></li>
              <li><a href="#"><i class="gmail"> </i></a></li>
            </ul>
          </div>
          <div class="col-md-6">
            <p>KOTHRUD</p>
            <p>Kothrud,Pune 411030</p>
            <p>office: 020 24451771 </p>
            <p>Mob: +91 9158560600</p>
            <p>Email:vatturkarsaraf589@gmail.com</p>
          </div>
        </div>


      </div>
      <div class="col-md-12 amet-sed">
        <div class="footer-class">
          <p>© 2015 Vatturekar Saraf All Rights Reserved</p>
        </div>
      </div>
    </div>
  </div>
</div> -->