<div id="carouselExampleCaptions" class="carousel slide">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <!-- <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img [src]="image1" class="d-block w-100" alt="...">
        <div class="carousel-caption1 d-none d-md-block">
          <h5>Elevate your look with our handcrafted earrings 
          </h5>
          <p>" made from the finest materials "</p>
          <div class="tbox"></div>
        </div>
      </div>
      <div class="carousel-item">
        <img [src]="image2" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>Our exquisite selection features freshwater pearls </h5>
          <p>" in various sizes and shades, expertly crafted into stunning necklaces. "</p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>