<div class="content-bottom">
    <ul>
        <li><a href="#"><img class="img-responsive" src="../../../assets/images/silver/lo.jpg" alt=""></a></li>
        <li><a href="#"><img class="img-responsive" src="../../../assets/images/silver/lo1.jpg" alt=""></a></li>
        <li><a href="#"><img class="img-responsive" src="../../../assets/images/silver/lo2.jpg" alt=""></a></li>
        <li><a href="#"><img class="img-responsive" src="../../../assets/images/silver/lo3.jpg" alt=""></a></li>
        <li><a href="#"><img class="img-responsive" src="../../../assets/images/silver/lo4.jpg" alt=""></a></li>
        <li><a href="#"><img class="img-responsive" src="../../../assets/images/silver/lo5.jpg" alt=""></a></li>
        <div class="clearfix"> </div>
    </ul>
</div>