<div class="container">
    <div class="row py-5">
        <div class="col-6 inner">
            <img [src]="image1" class="d-block w-100" alt="...">
            <div class="cmsbanner-text">
                <div class="offer-title">Up To 20% Save</div>
                <div class="main-title">Black Pearl Necklace</div>
                <div class="shopnow"><a class="shop-now" href="#">Shop Now</a></div>
            </div>
        </div>
        <div class="col-6 inner">
            <img [src]="image2" class="d-block w-100" alt="...">
            <div class="cmsbanner-text">
                <div class="offer-title">Up To 20% Save</div>
                <div class="main-title">Black Pearl Necklace</div>
                <div class="shopnow"><a class="shop-now" href="#">Shop Now</a></div>
            </div>
        </div>
    </div>
</div>