import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BigBannerComponent } from './home/big-banner/big-banner.component';
import { HomeComponent } from './home/home.component';
import { CollectionComponent } from './collection/collection.component';
import { OffersComponent } from './offers/offers.component';
import { SilverImgComponent } from './silver-img/silver-img.component';

@NgModule({
  declarations: [
    HomeComponent,
    BigBannerComponent,
    CollectionComponent,
    OffersComponent,
    SilverImgComponent
  ],
  imports: [
    CommonModule
  ]
})
export class HomeModule { }
