import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  LOGO = ("./assets/images/logo/brown-logo.png");
  THUM1 = ("./assets/images/navbar/thum1.jpg");
  constructor() { }

  ngOnInit(): void {
  }

}
