<!-- <div class="container-fluid" >
    <div class="item" *ngFor="let img of imgSlider">
        <img src="{{img.imgUrl}}" alt="">
        <div class="collection-details">
            <h2 class="title">{{img.title}}</h2>
            <div class="more">{{img.more}}</div>        
        </div>
    </div>  
</div> -->

<!-- 
<div id="collection-section container">
    <div class="item row">
        <div class="col-md-6">
            <a class="inner" href=""  *ngFor="let img of imgSlider">
                <div class="c-img">
                    <img src="{{img.imgUrl}}" alt="">
                </div>
                <div class="c-info">
                     <h2 class="title">{{img.title}}</h2>
                     <div class="details">{{img.more}}</div>
                     <span class="link">View Products</span>
                </div>
            </a>
        </div>
       
    </div>
</div> -->


<div id="collection" class="container-fluid no-gutter">
    <h2 class="title">FEATURED PRODUCTS</h2>
    <div class="row">
        <div class="col-md-6 a-box img-1">
            <div class="content">
                <h4>Fashion Bangles</h4>
                <p> If you're looking for bangles that are on-trend and stylish, our fashion bangles collection has everything you need. From chunky statement bangles to delicate minimalist designs, we have bangles to suit every style and taste.</p>

            </div>
        </div>
        <div class="col-md-6 a-box img-2">
            <div class="content">
                <h4>Birthstone Pendants</h4>
                <p>Our collection of birthstone pendants is the perfect way to celebrate a special someone's birthday or add a personal touch to your jewelry collection. Each pendant features a unique gemstone that corresponds to a specific birth month, making them a thoughtful and sentimental gift.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 a-box img-3">
            <div class="content">
                <h4>Traditional Temple Jewellery</h4>
                <p>Our collection of traditional temple jewellery is inspired by the intricate designs of ancient Indian temples. These pieces are handcrafted using traditional techniques and feature detailed motifs of gods and goddesses, floral patterns, and other traditional elements. They are perfect for special occasions such as weddings and festivals.</p>
            </div>

        </div>
        <div class="col-md-6 a-box img-4">
            <div class="content">
                <h4>Statement Necklaces</h4>
                <p>Our collection of statement necklaces is perfect for those who want to make a bold and dramatic statement with their jewelry. These necklaces feature oversized pendants, chunky chains, and vibrant colors that are sure to catch the eye.</p>
            </div>

        </div>
    </div>
</div>