import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-silver-img',
  templateUrl: './silver-img.component.html',
  styleUrls: ['./silver-img.component.scss']
})
export class SilverImgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
